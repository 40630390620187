import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "label" }
const _hoisted_2 = { class: "value" }

const EMPTY_VALUE = "-";


export default /*@__PURE__*/_defineComponent({
  __name: 'ReadonlyField',
  props: {
    label: {},
    value: { default: null }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "readonly-field" }, _ctx.$attrs), [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_ctx.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.value), 1)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(EMPTY_VALUE))
            ], 64))
      ])
    ])
  ], 16))
}
}

})