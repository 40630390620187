import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

import useQuasar from 'quasar/src/composables/use-quasar.js';;

import { TargetType, TargetUnified } from "@/api/target/typedefs";
import TargetDetail from "@/components/shared/target/card/TargetDetail.vue";
import TargetSelect from "@/components/shared/target/select/TargetSelect.vue";
import { usePaginatedSelect } from "@/composables/usePaginatedSelect";
import { ProductLine } from "@/lib/productLine";
import { ValidationRule } from "@/lib/rules/typedefs";
import TargetsModal from "@/views/targets/TargetsModal.vue";
import { TargetFormSuccessResponse } from "@/views/targets/useTargetForm";

import { FetchMultipleTargetFunction, FetchTargetFunction } from "../typedefs";


export default /*@__PURE__*/_defineComponent({
  __name: 'TargetCard',
  props: {
    fetchTarget: {},
    fetchTargets: {},
    hint: { default: null },
    isAddTargetButtonVisible: { type: Boolean },
    label: { default: null },
    modelValue: {},
    productLine: {},
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean },
    rules: { default: () => [] },
    isDetailedUi: { type: Boolean, default: false },
    errorMessage: { default: null },
    targetType: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const $q = useQuasar();

const emit = __emit;

const { fetchOptions, fetchMoreOptions, options } = usePaginatedSelect({
  paginatedFetchMethod: (query, page, perPage) =>
    props.fetchTargets(props.productLine.id, query, page, perPage),
});

const openTargetAddEditDialog = (targetToEditId?: number) => {
  $q.dialog({
    component: TargetsModal,
    componentProps: {
      targetType: props.targetType,
      productLineId: props.productLine.id,
      productId: props.productLine.productId,
      targetId: targetToEditId,
    },
  }).onOk(
    async (data: { payload: TargetFormSuccessResponse; event: string }) => {
      const targetId = Number(data.payload.targetId);
      if (!Number.isInteger(targetId)) return;
      const target = await props.fetchTarget(props.productLine.id, targetId);
      emit("update:modelValue", target);
    }
  );
};

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-h6 q-mb-sm" }, "Target", -1)),
          _createVNode(TargetSelect, {
            "data-test": "ia.form.field.target",
            "model-value": _ctx.modelValue,
            label: _ctx.label,
            hint: _ctx.hint,
            readonly: _ctx.readonly,
            disabled: _ctx.disabled,
            options: _unref(options),
            rules: _ctx.rules,
            "is-add-target-button-visible": _ctx.isAddTargetButtonVisible,
            "is-detailed-ui": props.isDetailedUi,
            "error-message": props.errorMessage,
            onFilter: _unref(fetchOptions),
            onVirtualScroll: _unref(fetchMoreOptions),
            onAddTarget: openTargetAddEditDialog,
            onEditTarget: _cache[0] || (_cache[0] = ($event: any) => (openTargetAddEditDialog($event?.id))),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
          }, null, 8, ["model-value", "label", "hint", "readonly", "disabled", "options", "rules", "is-add-target-button-visible", "is-detailed-ui", "error-message", "onFilter", "onVirtualScroll"]),
          (_ctx.modelValue?.id)
            ? (_openBlock(), _createBlock(TargetDetail, {
                key: 0,
                "target-id": _ctx.modelValue?.id,
                "product-id": _ctx.productLine.productId,
                "target-type": _ctx.targetType,
                "product-line-id": _ctx.productLine.id
              }, null, 8, ["target-id", "product-id", "target-type", "product-line-id"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})