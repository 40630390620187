import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, unref as _unref } from "vue"

const _hoisted_1 = { key: 0 }

import QSelect from 'quasar/src/components/select/QSelect.js';import useFormChild from 'quasar/src/composables/use-form-child.js';;
import { Ref, ref } from "vue";

import { TargetUnified } from "@/api/target/typedefs";
import { FilterEventPayload, ScrollEventPayload } from "@/api/typedefs";
import TargetSelectOption from "@/components/shared/target/select/TargetSelectOption.vue";
import { OnFilter, OnFilterAbortFn, OnFilterDoneFn } from "@/lib/quasar";
import { ValidationRule } from "@/lib/rules/typedefs.js";


export default /*@__PURE__*/_defineComponent({
  __name: 'TargetSelect',
  props: {
    hint: { default: null },
    isAddTargetButtonVisible: { type: Boolean },
    isDetailedUi: { type: Boolean, default: false },
    label: { default: null },
    modelValue: {},
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean },
    options: {},
    rules: { default: () => [] },
    errorMessage: { default: null }
  },
  emits: ["update:modelValue", "filter", "virtualScroll", "editTarget", "addTarget"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

function targetLabel(target: TargetUnified | null): string {
  if (target === null) return "";
  const affectedUsers = target.affectedUsers;

  if (affectedUsers === null) return target.name;

  return `${target.name} (~${affectedUsers} users)`;
}

const selectRef: Ref<QSelect | null> = ref(null);

const onScroll = ({ to, ref }: ScrollEventPayload) => {
  emit("virtualScroll", { to, ref });
};

const onFilter: OnFilter = (
  inputValue: string,
  doneFn: OnFilterDoneFn,
  abortFn: OnFilterAbortFn
) => {
  emit("filter", {
    value: inputValue,
    onSuccess: () => doneFn(() => undefined),
    onError: abortFn,
  });
};

useFormChild({
  validate: () => selectRef.value?.validate?.() ?? false,
  resetValidation: () => selectRef.value?.resetValidation(),
  requiresQForm: false,
});

return (_ctx: any,_cache: any) => {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_unref(QSelect), {
    ref_key: "selectRef",
    ref: selectRef,
    "model-value": _ctx.modelValue,
    filled: "",
    clearable: "",
    "use-input": "",
    readonly: _ctx.readonly,
    disable: _ctx.disabled,
    label: _ctx.label ?? undefined,
    hint: _ctx.hint ?? undefined,
    options: _ctx.options,
    rules: _ctx.rules,
    "option-label": targetLabel,
    "display-value": targetLabel(_ctx.modelValue),
    "error-message": _ctx.errorMessage ?? '',
    error: _ctx.errorMessage !== null,
    onFilter: onFilter,
    onVirtualScroll: onScroll,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }, {
    append: _withCtx(() => [
      (_ctx.modelValue !== null)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 0,
            round: "",
            dense: "",
            flat: "",
            icon: "edit",
            "data-test": "targetselect.button.edittarget",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('editTarget', _ctx.modelValue)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_tooltip, null, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Click here to edit seleted target")
                ])),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isAddTargetButtonVisible)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 1,
            round: "",
            dense: "",
            flat: "",
            icon: "add",
            disable: _ctx.readonly,
            "data-test": "targetselect.button.addtarget",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('addTarget')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_tooltip, null, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("Click here to add a new target")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["disable"]))
        : _createCommentVNode("", true)
    ]),
    selected: _withCtx(() => [
      (_ctx.modelValue)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(TargetSelectOption, {
              "data-test": "targetselect.selected",
              "target-name": _ctx.modelValue.name,
              "affected-users": _ctx.modelValue.affectedUsers,
              "is-detailed-ui": _ctx.isDetailedUi,
              "is-runtime": _ctx.modelValue.isRuntime
            }, null, 8, ["target-name", "affected-users", "is-detailed-ui", "is-runtime"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["model-value", "readonly", "disable", "label", "hint", "options", "rules", "display-value", "error-message", "error"]))
}
}

})